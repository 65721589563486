<template>
  <div class="bootcamp-list">
    <div
      v-for="bootcamp in bootcamps"
      :key="bootcamp.id"
      class="bootcamp-list__item">

      <div class="bootcamp-list__item-details">
        <div class="bootcamp-list__start-month">
          <div class="mb-4">{{ shortMonthName(bootcamp.start_date) }}</div>
          <div>{{ dateYear(bootcamp.start_date) }}</div>
        </div>

        <div class="bootcamp-list__start-day">
          Starts on {{ monthWithDay(bootcamp.start_date) }}, {{ new Date(bootcamp.start_date).getDate() }}
        </div>

        <div class="bootcamp-list__language">
          4 weeks
        </div>

        <div class="bootcamp-list__seats">
         {{ bootcamp.seats_left }} / {{ bootcamp.max_seats }} {{ $tc("seats", bootcamp.seats_left) }} left
        </div>

        <div class="bootcamp-list__prices">
          <div class="bootcamp-list__original-price" v-if="showOriginalPrice">
            {{ originalPrice }}
          </div>

          <div class="bootcamp-list__final-price">
            {{ finalPrice }}
          </div>
        </div>

        <div class="bootcamp-list__actions">
          <button
            class="btn mb-4"
            @click.prevent="toggleActiveBootcamp(bootcamp.id)">ENROLL NOW</button>

          <a
            href="#"
            @click.prevent="toggleActiveBootcamp(bootcamp.id)"
            class="bootcamp-list__more-details-link">
            {{ activeBootcampId === bootcamp.id ? "Hide details" : "More details" }}
          </a>
        </div>
      </div>

      <div
        v-if="activeBootcampId === bootcamp.id"
        class="bootcamp-list__pricing-plan">
        <BootcampPricingPlan
          :product="product"
          :bootcamp="bootcamp" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";
import BootcampPricingPlan from "components/bootcamp/bootcamp-pricing-plan";

const MIN_SEATS = 1;

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  name: "BootcampList",
  components: {
    BootcampPricingPlan
  },
  methods: {
    toggleActiveBootcamp(bootcampId) {
      if (this.activeBootcampId === bootcampId) {
        this.activeBootcampId = null;
      } else {
        this.activeBootcampId = bootcampId
      }
    },
    shortMonthName(bootcampStartDate) {
      const date = new Date(bootcampStartDate)
      const monthName = date.toLocaleString('en-US', { month: 'short' });

      return monthName.toUpperCase();
    },
    dateYear(bootcampStartDate) {
      const date = new Date(bootcampStartDate)
      const twoDigitYear = date.getFullYear()

      return twoDigitYear;
    },
    monthWithDay(bootcampStartDate) {
      const date = new Date(bootcampStartDate)
      return date.toLocaleString('en-US', { month: 'short' });
    }
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    originalPrice() {
      return currencyFormatter[this.product.currency](this.product.originalPrice);
    },
    showOriginalPrice() {
      return !!this.activeCouponCode
    },
    finalPrice() {
      if (this.product.quantityCoupons) {
        // TODO implement logic for quantityCoupons

        throw new Error("TODO implement logic for quantityCoupons")
      } else {
        const finalPrice = this.product.originalPrice;

        return currencyFormatter[this.product.currency](finalPrice);
      }
    }
  },
  data() {
    return {
      activeCouponCode: null,
      activeBootcampId: null
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bootcamps: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  i18n: {
    messages: {
      en: {
        seats: "seat | seats",
      }
    }
  }
}
</script>

<style lang="scss">
.bootcamp-list {
  &__item {
    padding: $px8;

    border: $px4 solid $grey-10;
    border-radius: $px8;

    &:not(:last-child) {
      margin-bottom: $px16;
    }
  }

  &__start-month {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $px16 $px12;

    font-size: $px24;
    font-weight: 900;
    line-height: 1;

    border-radius: $px8;

    background-color: $grey-10;
  }

  &__start-day {
    width: $px144;
  }

  &__item-details {
    display: flex;
    align-items: center;

    border-radius: $px8;

    @include breakpoint($md) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__final-price {
    font-size: $px24;
    font-weight: 700;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__more-details-link {
    @include font-small;
    color: $grey-4;
  }

  &__pricing-plan {
    margin-top: $px24;
  }
}
</style>
