<template>
  <div class="bootcamp-seats-calculator">
    <div
      class="bootcamp-seats-calculator__description"
      v-html="$options.filters.md(bootcamp.description)" />

    <div class="bootcamp-seats-calculator__calculator">
      <div class="bootcamp-seats-calculator__prices">
        <div class="bootcamp-seats-calculator__original-price" v-if="showOriginalPrice">
          {{ originalPrice }}
        </div>

        <div class="bootcamp-seats-calculator__final-price">
          {{ finalPrice }}
        </div>
      </div>

      <div class="form bootcamp-seats-calculator__form">
        <div class="form__form-group bootcamp-seats-calculator__size-input">
          <label for="">Number of seats</label>
          <input
            type="number"
            placeholder="42"
            v-model.number="numberOfSeats"
            :min="minNumberSeats"
            @input="updateNumberOfSeats">
        </div>

        <a
          :href="purchaseHref"
          class="btn bootcamp-seats-calculator__cta"
          :class="{
            'js-open-signup-popup': isGuest
          }"
          :data-after-auth="purchaseHref">
          {{ ctaCopy }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import currency from "currency.js";

const MIN_SEATS = 1;

const currencyFormatter = {
  usd: (value) => {
    return currency(value, {
      symbol: "$",
      precision: 2,
      fromCents: true,
      separator: ",",
      decimal: ".",
      pattern: "!#"
    }).format().replace(".00", "");
  },
  eur: (value) => {
    return currency(value, {
      symbol: "€",
      precision: 2,
      fromCents: true,
      separator: ".",
      decimal: ",",
      pattern: "#!"
    }).format().replace(",00", "");
  }
}

export default {
  name: "BootcampPricingPlan",
  methods: {
    updateNumberOfSeats(event) {
      if(parseInt(event.target.value) < MIN_SEATS) {
        return;
      }
    }
  },
  computed: {
    ...mapGetters("user", ["isGuest"]),
    ctaCopy() {
      if(this.numberOfSeats === 1) {
        return `Buy ${this.numberOfSeats} seat`;
      } else {
        return `Buy ${this.numberOfSeats} seats`;
      }
    },
    purchaseHref() {
      return `/purchases/new?slug=bootcamp_seat&quantity=${this.numberOfSeats}&coupon_code=${this.activeCouponCode}&bootcamp_id=${this.bootcamp.id}`;
    },
    originalPrice() {
      return currencyFormatter[this.product.currency](this.numberOfSeats * this.product.originalPrice);
    },
    showOriginalPrice() {
      return !!this.activeCouponCode
    },
    finalPrice() {
      if (this.product.quantityCoupons) {
        // TODO implement logic for quantityCoupons

        throw new Error("TODO implement logic for quantityCoupons")
      } else {
        const finalPrice = this.product.originalPrice * this.numberOfSeats

        return currencyFormatter[this.product.currency](finalPrice);
      }
    }
  },
  data() {
    return {
      numberOfSeats: MIN_SEATS,
      minNumberSeats: MIN_SEATS,
      activeCouponCode: null,
      event: {
        title: "Lecture 1. SQL Habit Product Analysis Bootcamp.",
        description: "Be there!",
        start: "2024-05-03 10:00:00 +0200",
        duration: [2, "hour"],
      }
    }
  },
  props: {
    product: {
      type: Object,
      default: () => {
        return {};
      }
    },
    bootcamp: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
}
</script>

<style lang="scss">
.bootcamp-seats-calculator {
  &__description {
    margin-bottom: $px24;
  }
  &__form {
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include breakpoint($md) {
      flex-direction: row;
    }
  }

  &__size-input {
    width: 100%;

    @include breakpoint($md) {
      width: auto;
      margin-right: $px8;
    }
  }

  &__cta {
    display: inline-block;
    width: 100%;
    margin-top: $px16;

    @include breakpoint($md) {
      width: auto;
    }
  }

  &__calculator {
    display: flex;
    align-items: center;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__original-price {
    margin-right: $px16;

    font-size: $px16;
    line-height: 1;
    font-weight: 500;
    text-decoration: line-through;
    color: $grey-7;
  }

  &__final-price {
    font-size: $px24;
    line-height: 1;
    font-weight: 700;
  }
}
</style>
