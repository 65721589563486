<template>
  <div
    class="question-upvote-button"
    :class="{
      'question-upvote-button--disabled': !buttonEnabled
    }"
    @click.prevent="upvoteQuestion"
    :disabled="!buttonEnabled">
    <div class="question-upvote-button__container">
      <ThumbsUpIcon
        class="question-upvote-button__icon" />

      <span>{{ this.totalUpvotesCount }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import ThumbsUpIcon from "@images/font_awesome/thumbs-up-light.svg";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      totalUpvotesCount: this.upvotesCount,
      makingApiCall: false
    }
  },
  props: ["upvotesCount", "questionId", "userCanUpvote"],
  components: {
    ThumbsUpIcon
  },
  computed: {
    ...mapGetters("user", ["loggedIn"]),
    buttonEnabled() {
      return !this.makingApiCall && this.loggedIn && this.userCanUpvote;
    }
  },
  methods: {
    upvoteQuestion() {
      if(this.makingApiCall) {
        return;
      }

      if(!this.userCanUpvote) {
        return;
      }

      if(!this.loggedIn) {
        return;
      }

      this.makingApiCall = true;
      let formData = new FormData();

      formData.append("forum_upvote[forum_question_id]", this.questionId);

      axios.post("/forum/upvotes.json", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.totalUpvotesCount += 1;
        this.makingApiCall = false;
      }).catch(function (error) {
        notify({
          message: "Something went wrong.",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  }
}
</script>

<style lang="scss">
.question-upvote-button {
  $root: &;

  display: inline-block;

  @include font-small;
  color: $grey-3;

  cursor: pointer;

  &__container {
    display: flex;
    align-items: center;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__icon {
    width: $px16;
    height: $px16;
    margin-right: $px4;

    color: $grey-3;
  }

  &--disabled {
    cursor: auto;

    #{$root}__container:hover {
      transform: scale(1);
    }
  }
}
</style>
