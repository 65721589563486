import hljs from "highlight.js/lib/core";
import pgsql from "highlight.js/lib/languages/pgsql";
import "highlight.js/styles/vs.css";

hljs.registerLanguage("pgsql", pgsql);

hljs.configure({
  ignoreUnescapedHTML: false,
  throwUnescapedHTML: false
});

export const highlightAll = () => {
  document.querySelectorAll("pre code").forEach((block) => {
    if (!block.dataset.highlighted) {
      hljs.highlightElement(block);
    }
  });
};

export const highlight = (code) => {
  return hljs.highlight(code, {
    language: "pgsql",
    ignoreIllegals: true
  });
};

export const highlightChildren = (parent) => {
  parent.querySelectorAll("pre code").forEach((block) => {
    if (!block.dataset.highlighted) {
      hljs.highlightElement(block);
    }
  });
};

highlightAll();
