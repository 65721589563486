import { Editor, CommandBar } from "tiny-markdown-editor";
import "tiny-markdown-editor/dist/tiny-mde.min.css";
import axios from "axios";
import { loadFontAwesome } from "../helpers/font-awesome";

const insertStoryHighlight = (editor) => {
  const pos = editor.getSelection()

  editor.paste(`\n<div class="story-highlight">\n\n</div>\n`, pos);

  editor.setSelection({
    row: pos.row + 2,
    col: 0
  });
};

const insertMathJaxFormula = (editor) => {
  const pos = editor.getSelection()

  editor.paste("\n{:.js-mathjax}\n  $\\Large{x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}}$\n", pos);

  editor.setSelection({
    row: pos.row + 2,
    col: 0
  });
};

const insertPgsql = (editor) => {
  const pos = editor.getSelection();

  editor.paste("\n~~~pgsql\n\n~~~\n");

  editor.setSelection({
    row: pos.row + 2,
    col: 0
  });
};

const insertQuiz = (editor) => {
  const pos = editor.getSelection();

  editor.paste(`\n<span class="js-reveal-quiz-answer">Show explanation</span>\n\n<div class="inline-quiz">\n\n</div>\n`);

  editor.setSelection({
    row: pos.row + 4,
    col: 0
  });
};

export const initTinyMDE = ($textArea) => {
  loadFontAwesome();

  let editor = new Editor({
    textarea: $textArea,
  });
  let commandBar;

  const $toolbar = $textArea.parentElement.querySelector(".js-tiny-mde-toolbar");

  const availableCommands = [
    "bold",
    "italic",
    "blockquote",
    "hr",
    "ul",
    "ol",
    "h2",
    "|",
    {
      name: "insertStoryHighlight",
      action: insertStoryHighlight,
      innerHTML: `<div class="fa fa-book"></div>`,
      title: "Insert Story Highlight"
    },
    {
      name: "insertQuiz",
      action: insertQuiz,
      innerHTML: `<div class="fa fa-question"></div>`,
      title: "Insert Quiz"
    },
    {
      name: "insertPgsql",
      action: insertPgsql,
      innerHTML: `<div class="fa fa-code"></div>`,
      title: "Insert PGSql"
    },
    {
      name: "insertMathJaxFormula",
      action: insertMathJaxFormula,
      innerHTML: `<div class="fa fa-usd"></div>`,
      title: "Insert MathJax formula"
    }
  ]

  if ($toolbar) {
    const commands = $toolbar.dataset.commands.split(",");
    commandBar = new CommandBar({
      element: $toolbar,
      editor,
      commands: availableCommands.filter(c => commands.includes(c?.name || c))
    });
  }

  if ($textArea.dataset.imageDrop) {
    editor.e.addEventListener("drop", function(event) {
      event.preventDefault();

      let formData = new FormData();

      if (event.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        for (var i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === 'file') {
            var file = event.dataTransfer.items[i].getAsFile();

            formData.append("inline_image", file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        for (var i = 0; i < event.dataTransfer.files.length; i++) {
          let file = event.dataTransfer.files[i];

          formData.append("inline_image", file);
        }
      }

      axios.post("/backoffice/inline_images", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(function (response) {
        const pos = editor.getSelection()

        editor.paste(`\n![${response.data.image_name}](${response.data.image_url})\n`, pos);
      })
      .catch(function (error) {
        Sentry.captureException(error);
      })
      .finally(function () {
      });
    });
  }

  return editor;
}

document.querySelectorAll(".js-tiny-mde").forEach(($textArea) => {
  initTinyMDE($textArea);
});
