<template>
  <div
    class="question-subscription"
    v-if="loaded">
    <div class="question-subscription__headline">
      EMAIL NOTIFICATIONS
    </div>

    <div class="question-subscription__description">
      Subscribe to email notifications about new replies
    </div>

    <button
      class="question-subscription__cta btn btn--sm btn--flex btn--full-width"
      :class="{
        'js-open-signup-popup': isGuest
      }"
      @click.prevent="toggleState">

      <span
        class="question-subscription__cta-body"
        :class="{
          'js-open-signup-popup': isGuest
        }"
        v-if="subscribed">
        <VolumeOff
          class="question-subscription__cta-icon"
          :class="{
            'js-open-signup-popup': isGuest
          }" />
        UNSUBSCRIBE
      </span>

      <span
        class="question-subscription__cta-body"
        :class="{
          'js-open-signup-popup': isGuest
        }"
        v-else>
        <Volume
          class="question-subscription__cta-icon"
          :class="{
            'js-open-signup-popup': isGuest
          }" />
        SUBSCRIBE
      </span>
    </button>
  </div>
</template>

<script>
import axios from "axios";
import { notify } from "modules/notify";
import Volume from "@images/font_awesome/volume-light.svg"
import VolumeOff from "@images/font_awesome/volume-mute-light.svg"
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      questionId: window.state.questionId,
      subscribed: false,
      loaded: false
    }
  },
  components: {
    Volume,
    VolumeOff
  },
  computed: {
    ...mapGetters("user", ["loggedIn", "isGuest"])
  },
  methods: {
    toggleState() {
      if(this.isGuest) {
        return;
      }

      const formData = new FormData();

      axios.patch(`/forum/question_subscriptions/toggle.json?question_id=${this.questionId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]").content
        }
      }).then(response => {
        this.subscribed = response.data.subscribed;
      }).catch(function (error) {
        console.warn(error)

        notify({
          message: "Smth went wrong",
          type: "alert"
        });

        Sentry.captureException(error);
      });
    }
  },
  mounted() {
    axios.get(`/forum/question_subscriptions/status.json?question_id=${this.questionId}`).then(response => {
      this.subscribed = response.data.subscribed;
      this.loaded = true;
    }).catch(function (error) {
      notify({
        message: "Something went wrong.",
        type: "alert"
      });

      Sentry.captureException(error);
    });
  }
}
</script>

<style lang="scss">
.question-subscription {
  padding: $px12;

  border-radius: $px4;

  background-color: $grey-10;

  &__headline {
    margin-bottom: $px12;

    @include font-small;
  }

  &__description {
    margin-bottom: $px12;

    @include font-small;
  }

  &__cta {
    align-items: center;
    justify-content: center;
  }

  &__cta-body {
    display: flex;
    align-items: center;
  }

  &__cta-icon {
    width: $px16;
    height: $px16;
    margin-right: $px8;
  }
}
</style>
